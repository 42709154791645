//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import GroupCreate from '@/components/Group/GroupCreate';
import EventCreate from '@/components/Event/EventCreate';
import ItemCreate from '@/components/Marketplace/ItemCreate';
import StoreCreate from '@/components/Store/StoreCreate';
export default {
    components:{
        GroupCreate,
        EventCreate,
        ItemCreate,
        StoreCreate,
        LeftSidebar: () => import('@/components/Sidebars/LeftSidebar.vue')
    },
    data(){
        return {
            entityType: this.$route.params.type
        }
    },
    methods:{
        checkEntity (){
            if( this.entityType != 'event' && 
                this.entityType != 'group'  && 
                this.entityType != 'marketplace' && 
                this.entityType != 'store' )
            {
                this.$router.push('/')
            }
        }
    },
    watch: {
        '$route.params.type'(){
            location.reload();
        }
    },
    created(){
        if (this.entityType == 'marketplace' || this.entityType == 'store') {
            this.$router.push('/marketplace');
        }
        this.checkEntity(this.$route.params.type)
    }
}
