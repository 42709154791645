//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: "GroupCreate",
    props: [
        'open' 
    ],
    data() {
        return {
            groupName: '',
            groupPrivacy: 'Public'
        }
    },
    methods: {
        create() {
            if(this.groupName != ''){
            this.$q.loading.show({
                message: 'Creating group..'
            });
            
            this.$http({
                method: 'POST',
                url: 'groups',
                data: {
                    name: this.groupName,
                    privacy: this.groupPrivacy
                }      
            }).then((response) => {
                switch(response.data['status']) {
                case 'group_created':
                    this.$q.loading.hide();
                    this.$router.push(
                        { 
                            name: 'Group', 
                            params: 
                                { 
                                    id: response.data['body']['id'] 
                                }
                        }
                    );
                    break;
                default:
                    this.$q.loading.hide();                    
                    this.$q.notify({
                        message: 'There has been a mistake. Please try again.'
                    });
                }
            });
        }
        else{
             this.$q.notify({
                message: 'Group name is required.',
                color: 'negative'
            });
        }
        },

        /**
         * Cancels the group creations.
         */
        cancel() {
            this.$router.push({name: 'Home'})
        }
    }
}
