//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
    name: 'ItemCreate',

    data(){
      return{
        product_photo:null,
        preview_product_photo: '',
        item_name: '',
        item_price: '',
        item_description: '',
        item_quantity: '',
        page: 1,
        pagination: [],
        item_id: '',
        previewImgList: [],
        files: null,
        isuploadMedia: false
      }
    },
    methods:{
        togglePickFiles(){
            this.$refs.mediaFiles.pickFiles()
        },
        PreviewPostImages(){
            this.previewImgList = []
             Array.from(this.files).forEach((file) => {
                 if(file) {
                     let reader = new FileReader
                reader.onload = e => {
                    this.previewImgList.push({
                        counter: this.imgindex++,
                        imgsrc: e.target.result
                    })
                }
                reader.readAsDataURL(file)
                }
                this.totalMediaFiles = this.files.length
            })
        },
        getBackground: function (file){
            if(file) {
                let reader = new FileReader
            reader.onload = e => {
                return  '{background: url(' + e.target.result +') }'
            }
            reader.readAsDataURL(file)
            }
             
        },
        removeFile(){
            this.files = []
            this.previewImgList = []
        },
         onSubmitItem(){
            this.$refs.CreateItemForm.validate().then (success =>{
                if(success){
                    var formData = new FormData();
                    formData.append('title', this.item_name);
                    formData.append('price',this.item_price);
                    formData.append('description',this.item_description);
                    this.payload = {
                       data:formData
                    }
                    this.$store.dispatch('marketplace/createItem',this.payload)
                    .then((response) =>{
                        if(response.status == 'success'){
                            this.$q.notify({
                                message:'Successfully created an item.',
                                color: 'positive'
                            })
                            this.isuploadMedia = true
                            this.item_id = response.data
                        }else{
                            this.$q.notify({
                                message:'Sorry, We cant process your request right now.',
                                color: 'negative'
                            })
                        }
                    })
                }
            })
        },
        submitItemMedia(){
          this.$q.dialog({
            title: 'Uploading medias...',
            message: 'Are you sure you want to use this images?',
            cancel:true,
          })
          .onOk(() =>{
            if(this.files.length > 10){ this.files.splice(0,10) }
            this.files.map((file) => {
              var formData = new FormData();
              formData.append('medias', file);
              this.payload = {
                id: this.item_id,
                data: formData
              }
              this.$store.dispatch('marketplace/addItemMedia',this.payload)
            })
              this.$router.push({name:'MarketplaceItem',params:{id:this.item_id}})

          })
          .onCancel(() => {})
          .onDismiss(() => {})
        },
          
    },

    created(){
    }
}
