//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import EventForm from '@/components/Event/EventForm'
export default {
    name: 'EventCreate',
    components: {
        EventForm
    },
    data() {
        return {
            isCreating: false
        }
    },
    methods: {
        onSubmit(payload) {
            this.isCreating = true;
            this.$store.dispatch('event/createEvent', payload).then((response) => {
                this.isCreating = false;
                if (response.message == 'success') {
                    this.$router.push({name: 'Event', params: {id: response.id}});
                } else {
                    this.$q.notify({
                        message: 'An error has occurred while trying to create event.',
                        color: 'negative',
                        icon: 'error'
                    });
                }
            })
        }
    }
}
