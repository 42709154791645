//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
    name: 'StoreCreate',

    data(){
      return{
        product_photo:null,
        panel: 'store',
        store_name: '',
        store_privacy: 'Public',
        email_address: '',
        contact_1: '',
        contact_2:'',
        location: '',
        store_description: '',
        preview_product_photo: '@/assets/item13.jpg',
        item_name: '',
        item_price: '',
        item_description: '',
        item_quantity: '',
        store_id: '',
        store_ids: [],
        page: 1,
        pagination: [],
        item_id: '',
         previewImgList: [],
         files: null,
         isuploadMedia: false
      }
    },
    methods:{
        onSubmitStore(){
            this.$refs.CreateStoreForm.validate().then (success =>{
                if(success){
                  // Add prefix if has value
                  this.contact_1 ? '0'+this.contact_1 : '';
                  this.contact_2 ? '0'+this.contact_2 : ''
                  
                    this.payload = {
                        name: this.store_name,
                        privacy: this.store_privacy,
                        description: this.store_description,
                        location: this.location,
                        email_address: this.email_address,
                        contact_number: this.contact_1,
                        contact_number_2: this.contact_2,
                    }

                    this.$store.dispatch('store/createStore',this.payload)
                    .then((r) => {
                        if(r.status == 'success'){
                            this.$q.notify({
                                message:'Successfully created a store',
                                color:'positive',
                                icon: 'check_circle'
                            })
                            this.$router.push({name:'StoreList',params:{id:r.data.user_id}})
                        }else if(r.status == 'store_name_taken'){
                            this.$q.notify({
                                message:'Store name already taken!',
                                color:'negative',
                                icon: 'error'
                            })
                        }
                        else{
                            this.$q.notify({
                                message:'Unable to create your store',
                                color:'negative',
                                icon:'error'
                            })
                        }
                    })
                }
            })
        },

        togglePickFiles(){
            this.$refs.mediaFiles.pickFiles()
        },
        PreviewPostImages(){
            this.previewImgList = []
             Array.from(this.files).forEach((file) => {
                 if(file) {
                     let reader = new FileReader
                reader.onload = e => {
                    this.previewImgList.push({
                        counter: this.imgindex++,
                        imgsrc: e.target.result
                    })
                }
                reader.readAsDataURL(file)
                }
                this.totalMediaFiles = this.files.length
            })
        },
        getBackground: function (file){
            if(file) {
                let reader = new FileReader
            reader.onload = e => {
                return  '{background: url(' + e.target.result +') }'
            }
            reader.readAsDataURL(file)
            }
             
        },
        removeFile(){
            this.files = []
            this.previewImgList = []
        },

         onSubmitItem(){
            this.$refs.CreateItemForm.validate().then (success =>{
                if(success){
                    this.payload = {
                        store_id: this.store_id.id,
                        data: {
                            item_name: this.item_name,
                            price: this.item_price,
                            description: this.item_description,
                            quantity: this.item_quantity
                        }
                        
                    }
                    this.$store.dispatch('store/createItems',this.payload)
                    .then((response) =>{
                        if(response.status == 'success'){
                            this.isuploadMedia = true
                            this.item_id = response.data
                      
                        }else{
                            this.$q.notify({
                                message:'Sorry, We cant process your request right now.',
                                color: 'negative'
                            })
                        }
                    })
                }
            })
        },
        submitItemMedia(){
          this.$q.dialog({
            title: 'Uploading medias...',
            message: 'Are you sure you want to use this images?',
            cancel:true,
          })
          .onOk(() =>{
            var formData = new FormData();
            formData.append('file', this.files[0]);
            this.payload = {
              item_id: this.item_id,
              store_id: this.store_id.id,
              data: formData
            }
            this.$store.dispatch('store/uploadItemMedias',this.payload)
            .then((r) => {
              if(r.status == 'success'){
                this.$router.push({name:'StoreItem',params:{store_id:r.store_id,item_id:r.item_id}})
              }
            })
          })
          .onCancel(() => {})
          .onDismiss(() => {})
        },

        fetchStores(){
            this.payload = {
                page:this.page,
                q:'',
            }
           this.$store.dispatch('store/fetchStores',this.payload)
           .then((r) => {
               if(r == 'success'){
                   this.store_ids = this.$store.state.store.storelist
               }
           })
        },
          
    },

    created(){
        this.fetchStores()
    }
}
